import { Text } from '@workos-inc/component-library';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsPingFederate: ArrayStepsConfig = {
  providerLabel: 'PingFederate SCIM',
  type: 'Array',
  steps: [
    {
      name: 'Install the SCIM Connector in PingFederate',
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            There are several prerequisite steps required in PingFederate for
            this integration. First, download and install the{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://docs.pingidentity.com/bundle/pingfederate-scim-connector/page/ulk1563995050657.html'
              }
            >
              PingFederate SCIM Connector
            </Link>
            .
          </Text>

          <Text>
            Next, deploy the SCIM Connector files to your PingFederate directory
            following{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://docs.pingidentity.com/bundle/pingfederate-scim-connector/page/dcn1563995073633.html'
              }
            >
              the documentation from PingFederate
            </Link>
            .
          </Text>

          <Text>
            Finally, enable provisioning in PingFederate using the{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://docs.pingidentity.com/bundle/pingfederate-scim-connector/page/xnt1568332743066.html'
              }
            >
              documentation provided by PingFederate
            </Link>
            .
          </Text>

          <Confirm
            label="I’ve installed the SCIM Connector, deployed the SCIM Connector Files, and enabled Provisioning."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Create a SCIM Connector Application in PingFederate',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Log in as an Administrator to your PingFederate instance, and select
            "Applications" {'> '}
            "SP Connections".
          </Text>

          <Img
            className="my-8"
            height={1382}
            src="/images/82c127f9-3309-4f76-8bd1-267ff839e9da.png"
            width={2118}
          />

          <Text>Select "Create Connection".</Text>

          <Img
            className="my-8"
            height={1382}
            src="/images/59168455-f847-441a-9af7-71e0a9778a99.png"
            width={2118}
          />

          <Text>
            On the Connection Template page, select "Use a Template for this
            Connection" and then select "SCIM Connector" from the dropdown list.
            If you don’t see the SCIM Connector option, go back to the Install
            SCIM Connector in PingFederate step. Click "Next".
          </Text>

          <Img
            className="my-8"
            height={1156}
            src="/images/401ed988-9cdd-4e29-9823-cbb3be44d86e.png"
            width={2490}
          />

          <Text>
            On the Connection Type page, make sure Outbound Provisioning is
            checked with the SCIM Connector Type. Click "Next".
          </Text>

          <Img
            className="my-8"
            height={1214}
            src="/images/64077d0b-ae10-44e0-987e-16242656e937.png"
            width={2490}
          />

          <Text>
            On the General Info page, give this connection a descriptive name,
            and click "Next".
          </Text>

          <Img
            className="my-8"
            height={2222}
            src="/images/dd692373-be1d-47f6-b139-fb70298b1a6e.png"
            width={2490}
          />

          <Confirm
            label="I have created an SP SCIM Connection in PingFederate."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Configure Outbound Provisioning for the PingFederate Application',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            On the Outbound Provisioning page, select the "Configure
            Provisioning" button.
          </Text>

          <Img
            className="my-8"
            height={1226}
            src="/images/fa5d4aaf-4f10-41c4-abfb-ce38126a0e63.png"
            width={2490}
          />

          <Text>
            On the Target page, paste in the Endpoint in the{' '}
            <code>SCIM URL</code> field. Make sure <code>SCIM Version</code> is
            set as <code>2.0</code> and the <code>Authentication Method</code>{' '}
            is set as <code>OAuth 2 Bearer Token</code>. Paste the Bearer Token
            into the Access Token field.
          </Text>

          <CopyInput
            label="Copy and paste the endpoint into the SCIM URL field."
            value={directory?.endpoint}
          />

          <CopyInput
            label="Copy and paste the Bearer Token in to the Access Token field. "
            type="password"
            value={directory?.bearerToken}
          />

          <Img
            className="my-8"
            height={3846}
            src="/images/b9438dae-cdb7-437e-850b-ecb68492938c.png"
            width={2490}
          />

          <Text>
            Once these fields have been entered in to PingFederate, click Next.
          </Text>

          <Confirm
            label="I have entered the SCIM URL and Bearer Token. "
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Configure Channel in PingFederate',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>On the Manage Channels page, select "Create".</Text>

          <Img
            className="my-8"
            height={1232}
            src="/images/8ac8e3fb-86d2-4af1-8706-6b692112d032.png"
            width={2490}
          />

          <Text>
            On the Channel Info page, add a descriptive name and click "Next".
          </Text>

          <Img
            className="my-8"
            height={1312}
            src="/images/02be3fda-0c70-459a-b5ef-c0f5a461a018.png"
            width={2490}
          />

          <Text>
            Select an "Active Data Store" from the dropdown menu. This example
            uses a PingDirectory LDAP instance, but this may be different
            depending on the type of datastore used in each case. Please refer
            to the{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://docs.pingidentity.com/bundle/pingfederate-103/page/vbe1564003005413.html'
              }
            >
              PingFederate documentation
            </Link>{' '}
            for specific settings on your type of datastore. Click "Next".
          </Text>

          <Img
            className="my-8"
            height={1172}
            src="/images/cb10de17-32f9-49a9-b2eb-9860f64ae9dd.png"
            width={2490}
          />

          <Text>
            On the Source Settings page, make any modifications needed for your
            datastore. In this example, the default values for the LDAP
            datastore did not need to be modified, so the default settings were
            used. After configuring the source settings specific to your use
            case, click "Next" to go to the Source Location page.
          </Text>

          <Img
            className="my-8"
            height={3120}
            src="/images/a1915cbe-eedb-4fbd-ad78-81f41d8eb37c.png"
            width={2490}
          />

          <Text>
            On the Source Location page, input a Base DN and either a Group DB
            or Filter for the Users. This tells your application where to look
            for the users to sync from your active data store. The setup used in
            each case may be different depending on the type of datastore being
            used and which users and groups are to be provisioned. Please
            reference the{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://docs.pingidentity.com/bundle/pingfederate-103/page/jqa1564003005539.html'
              }
            >
              PingFederate documentation
            </Link>{' '}
            for specific steps. When this is complete, click "Next".
          </Text>

          <Img
            className="my-8"
            height={1564}
            src="/images/78ce8b2a-6339-4e9e-bd2c-d1f4488fac92.png"
            width={2490}
          />

          <Confirm
            label="I’ve configured the Channel Info, Source Settings, and Source Location. "
            onClick={handleNextStep}
          />
        </>
      ),
    },

    {
      name: 'Configure Attribute Mapping for your PingFederate Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            On the Attribute Mapping page, configure the mapping of attributes
            in the datastore to the SCIM attributes. The exact configuration
            will depend on the specific setup in each unique situation. For this
            PingDirectory LDAP example, the default settings are used. When
            finished, Click "Next".
          </Text>

          <Img
            className="my-8"
            height={3912}
            src="/images/7e5a4522-77e1-4e06-8808-249edccffdd7.png"
            width={2468}
          />

          <Text>
            On the Activation {'&'} Summary page, check that the settings are
            complete and the toggle for <code>Channel Status</code> is set to{' '}
            <code>Active</code> and select "Done".
          </Text>

          <Img
            className="my-8"
            height={2162}
            src="/images/2862523c-a733-4a5e-b547-6ec5cb216beb.png"
            width={2468}
          />

          <Text>
            When you are directed back to the Manage Channels page, select
            "Done".
          </Text>

          <Img
            className="my-8"
            height={854}
            src="/images/41f954ef-0a75-4806-b6b4-53629c9a9e20.png"
            width={2468}
          />

          <Text>
            You’re then directed to the Outbound Provisioning page. Select
            "Next".
          </Text>

          <Img
            className="my-8"
            height={854}
            src="/images/42690024-97be-42ac-867d-e45403411d78.png"
            width={2468}
          />

          <Confirm
            label="I’ve finished configuring, and have activated, the Outbound Provisioning Channel."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Activate the SP Connection in PingFederate',
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            On the Activation {'&'} Summary page, turn on provisioning with the
            toggle at the top, and then select "Save".
          </Text>

          <Img
            className="my-8"
            height={2778}
            src="/images/eb68ab8a-8ae6-47a5-bb6c-2361e84502f1.png"
            width={2432}
          />

          <Text>
            You’ll now see your SCIM application listed in the SP Connections
            page.
          </Text>

          <Img
            className="my-8"
            height={954}
            src="/images/83bbf028-4ceb-4e0a-9fd7-6956a0d10f7e.png"
            width={2432}
          />

          <Text>
            The provisioning will automatically begin when the connection is
            activated through outbound requests from Ping Federate. It may take
            a few minutes for this process to start.
          </Text>

          <Confirm
            label="I’ve activated the SP Connection in PingFederate."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      // eslint-disable-next-line react/display-name
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            The attributes that will be available to map from PingFederate will
            be determined by what you have set up in step 5 and may be unique to
            each configuration.
          </Text>

          <Text>
            Refer to the Attribute Mapping section of your PingFederate SP
            Connection to review the attributes that are available.
          </Text>

          <Img
            priority
            height={900}
            src="/admin-portal/directory-sync/pingfederate-scim/v1/pingfederate-scim-13.png"
            width={2000}
          />

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </MapAttributesStep>
      ),
    },
  ],
};
