import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const DuoSamlCreateConfiguration: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      The Duo SAML integration is unique in that it requires a 3rd party IDP to
      federate the authentication. This means that along with the four pieces of
      information, you’ll also need to configure a Single Sign on Authentication
      Source and a Cloud Application in your Duo Workspace.
    </Text>

    <Text>
      The high level overview of the authentication flow for this Service
      Provider initiated login:
    </Text>

    <Img
      priority
      height={210}
      src="/admin-portal/sso/duo/v1/duo-saml-1.png"
      width={1475}
    />

    <Text>
      WorkOS will allow you to use any Duo supported IDP to handle the Federated
      authentication. Since each IDP will have different ways of setting up the
      SSO connection between Duo and the IDP, please refer to the documentation
      that Duo provides to configure a Duo SSO Connection.
    </Text>

    <Text>
      <Link
        newTab
        appearance="highlight"
        aria-label="Link to the Duo SSO App documentation"
        href="https://duo.com/docs/sso#enable-duo-single-sign-on"
      >
        Duo Single Sign-on Application Documentation
      </Link>
    </Text>

    <Confirm
      label="I’ve created an SSO application in Duo."
      onClick={handleNextStep}
    />
  </>
);

export const DuoSamlCreateCloudApplication: FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      After configuring the Duo SSO Connection with the IDP of your choice, the
      next step is to create a Cloud Application in Duo. This app will handle
      the connection between WorkOS and Duo.
    </Text>

    <Text>
      Navigate to the Duo Admin Panel and click on Applications on the left
      sidebar. Click on the "Protect an Application" button.
    </Text>

    <Img
      className="my-8"
      height={568}
      src="/images/cca2c337-cdb8-4247-a5d5-1a68f4121994.png"
      width={1046}
    />

    <Text>
      Locate the entry for “Generic Service Provider” with a protection type of
      "2FA with SSO hosted by Duo (Single Sign-On)" in the applications list.
      Click "Protect" to the far-right to start configuring "Generic Service
      Provider".
    </Text>

    <Img
      className="my-8"
      height={719}
      src="/images/8404f0a8-a56f-4c58-b03e-d0ec48ef66b8.png"
      width={1152}
    />

    <Confirm
      label="I’ve created a Duo Cloud Application."
      onClick={handleNextStep}
    />
  </>
);

export const DuoSamlProvideEntityId: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Next, you'll begin to configure the Generic Service Provider settings,
      starting with the Entity ID. WorkOS provides the Service Provider Entity
      ID, which you can copy below.
    </Text>

    <CopyInput
      label="Copy this Identifier"
      value={connection?.saml_entity_id}
    />

    <Text>
      Copy the provided Entity ID and paste it into the "Entity ID" field under
      the "Service Provider" section.
    </Text>

    <Img
      className="my-8"
      height={1040}
      src="/images/8562779e-d625-48f3-a024-1522bcc84092.png"
      width={2188}
    />

    <Confirm
      label="I’ve configured the Service Provider Entity ID in Duo."
      onClick={handleNextStep}
    />
  </>
);

export const DuoSamlUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="http://www.duo.com/exkgq2c19CUpt2Brr46"
    idpSsoUrlPlaceholder="http://www.duo.com/exkgq2c19CUpt2Brr46"
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The next step is to obtain the Metadata URL from Duo and enter it in
      WorkOS. The Metadata URL can be found in your application settings page
      within Duo.
    </Text>

    <Img
      className="my-8"
      height={1426}
      src="/images/625ff0fa-2c12-43e9-8b7a-66aa4c670423.png"
      width={2226}
    />

    <Text>Paste the Metadata URL in the field below.</Text>
  </MetadataUrlStep>
);

export const DuoSamlEnterAcsUrl: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Next, copy the ACS URL from WorkOS and paste it in the Generic Service
      Provider settings towards the bottom of the page under "Assertion Consumer
      Service (ACS) URL"
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Img
      className="my-8"
      height={1040}
      src="/images/be71f9be-752a-4aed-8b1d-b2544745babf.png"
      width={2188}
    />

    <Text>
      You may leave the Single Logout URL, Service Provider Login URL, and
      Default Relay State fields empty.
    </Text>

    <Confirm
      label="I’ve finished configuring the ACS URL."
      onClick={handleNextStep}
    />
  </>
);

export const DuoSamlConfigureResponseSettings: FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      Scroll down on this page in Duo to the SAML Response section. Ensure that
      the NameID format has the id that you’d like to use for the unique
      identifier selected and matches the NameID attribute that you’d like to
      use as the value. If you’re using email as the unique id, the options
      would look like the below.
    </Text>

    <Img
      className="my-8"
      height={1426}
      src="/images/1f7a60a3-2c15-4d99-9ec2-b0df0895fabc.png"
      width={2226}
    />

    <Text>
      Ensure the Signature algorithm is SHA256 and that the Signing options have
      both Sign response and Sign assertion selected.
    </Text>

    <Img
      className="my-8"
      height={1426}
      src="/images/0349fcdd-a458-4691-8626-78243081f47e.png"
      width={2226}
    />

    <Confirm
      label="I’ve finished configuring the SAML response settings."
      onClick={handleNextStep}
    />
  </>
);

export const DuoSamlConfigureClaims: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      The final step is to make sure that you are mapping the attributes which
      WorkOS requires: <code>id</code>, <code>email</code>,
      <code>firstName</code>, and <code>lastName</code>. In the Map Attributes
      section enter these on the right side under SAML Response Attribute. on
      the left side, click the empty field box and select the pre-populated
      values that look like e.g. "{'<Email Address>'}". Duo will automatically
      grab the corresponding fields and map them to the expected values.
    </Text>

    <Text>
      You can map any values you like, but WorkOS requires that these four
      values are included in SAML responses. If your users don’t have a last
      name value for instance, you could map Display Name or any other value to{' '}
      <code>lastName</code>, but <code>lastName</code> still needs to be
      included or WorkOS will reject the SAML Response.
    </Text>

    <Text>
      Ensure the Match attributes section of your General Service Provider
      Application Settings page in Duo aligns with the following.
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I’ve finished configuring the SAML claims."
      onClick={handleNextStep}
    />
  </>
);
